import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import ButtonOrangeArrow, { Arrow } from 'components/elements/ButtonOrangeArrow'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const StyledLatestNews = styled.section``

const LatestNewsAll = styled(ButtonOrangeArrow)`
  @media (min-width: 992px) {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

interface LatestNewsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestNews
}

const LatestNews: React.FC<LatestNewsProps> = ({ fields }) => (
  <StyledLatestNews className="my-1 my-lg-5">
    <div className="container py-5">
      <ParseContent content={fields?.description} />
      <div className="mt-3">
        <LatestNewsAll isContrast to={fields?.link?.url || ''}>
          {fields?.link?.title}
        </LatestNewsAll>
      </div>
      <Blogik
        settings={{
          id: 'latest-news',
          limit: fields?.view === 'custom' ? null : 4,
          postIds:
            fields?.view === 'custom'
              ? fields?.posts?.map((post) => post?.uri)
              : [],
        }}
      >
        <BlogConsumer>
          {(context: any) => (
            <div className="row mt-sm-0 mt-4">
              <div className="col-6 pt-4 pt-sm-5">
                <div className="row justify-content-end">
                  <div className="col-12 col-md-11 col-lg-10">
                    <Post fields={context.posts[0].node} />
                  </div>
                  {context.posts[2] && (
                    <div className="col-sm-10 col-md-9 col-lg-7 mt-4">
                      <Post fields={context.posts[2].node} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  {context.posts[1] && (
                    <div className="col-sm-10 col-md-9 col-lg-7">
                      <Post fields={context.posts[1].node} />
                    </div>
                  )}
                  {context.posts[3] && (
                    <div className="col-12 col-md-11 col-lg-10 mt-4">
                      <Post fields={context.posts[3].node} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </BlogConsumer>
      </Blogik>
    </div>
  </StyledLatestNews>
)

const StyledPost = styled(Link)`
  display: block;
`

const PostMotion = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.background};
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const PostImage = styled.div`
  width: 100%;

  @media (min-width: 576px) {
    height: 225px;
  }

  @media (max-width: 575px) {
    height: 150px;
  }
`

const PostDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: ${({ theme }) => theme.font.size.l};
`

const PostLink = styled(ButtonOrangeArrow)`
  color: ${({ theme }) => theme.color.text.purple};
`

interface PostProps {
  fields: {
    post: {
      thumbnail: any
      excerpt: string
    }
    uri: string
  }
}

const Post: React.FC<PostProps> = ({ fields }) => (
  <StyledPost to={fields.uri}>
    <PostMotion whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
      <PostImage>
        <Image
          image={fields.post.thumbnail}
          alt="AddComm"
          className="w-100 h-100"
        />
      </PostImage>
      <div className="py-3 px-3 px-md-4">
        <PostDescription content={fields.post.excerpt} />
        <div className="text-right mt-3">
          <PostLink isCustom to={fields.uri}>
            Lees meer
            <Arrow />
          </PostLink>
        </div>
      </div>
    </PostMotion>
  </StyledPost>
)

export default LatestNews
